<template>
  <div v-loading="loading" class="student">
    <div class="operate">
      <filter-component
        :options="filterOptions"
        @change="getFilterValue"
      ></filter-component>
      <div class="operate-btn">
        <el-button type="primary" plain @click="add">新增</el-button>
        <el-button type="success" plain @click="importExcel">
          批量新增
        </el-button>
        <el-button type="success" plain @click="exportExcel">导出</el-button>
        <el-button type="primary" plain @click="batchEdit">批量修改</el-button>
        <el-button type="danger" plain @click="clearStudent"
          >清除学生</el-button
        >
        <el-button type="primary" plain @click="batchSearch"
          >批量搜索</el-button
        >
        <el-button
          v-if="$route.query.password == 1"
          type="primary"
          plain
          :loading="btnLoading"
          @click="exportStuSheet()"
        >
          批量导出学生答题卡
        </el-button>
        <el-dropdown
          trigger="hover"
          :hide-on-click="false"
          style="margin-left: 10px"
        >
          <span class="el-dropdown-link">
            <el-button
              >字段显示<i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
          </span>
          <template #dropdown>
            <el-dropdown-menu slot="dropdown">
              <el-checkbox-group v-model="checkList" @change="checkListChange">
                <el-dropdown-item
                  v-for="(item, index) in labelList"
                  :key="index"
                >
                  <el-checkbox :label="item.sort">{{ item.label }}</el-checkbox>
                </el-dropdown-item>
              </el-checkbox-group>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="tableList">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column
          v-for="item in labels"
          :key="item.sort"
          :prop="item.prop"
          :width="
            labels.length < 10 ? 'auto' : item.width ? item.width : 'auto'
          "
          :label="item.label"
          align="center"
        >
          <el-table-column
            v-if="item.prop === 'firstClass'"
            prop="firstSubjectName"
            label="学科"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.studentSubjectList.length">{{
                row.studentSubjectList[0].subjectName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="item.prop === 'firstClass'"
            prop="firstClassNum"
            label="教学班"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.studentSubjectList.length">{{
                row.studentSubjectList[0].subjectClass
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column
            v-if="item.prop === 'secondClass'"
            prop="secondSubject"
            label="学科"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.studentSubjectList.length">{{
                row.studentSubjectList[1].subjectName
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="item.prop === 'secondClass'"
            prop="secondClassNum"
            label="教学班"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.studentSubjectList.length">{{
                row.studentSubjectList[1].subjectClass
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column
            v-if="item.prop === 'thirdClass'"
            prop="thirdSubjectName"
            label="学科"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.studentSubjectList.length">{{
                row.studentSubjectList[2].subjectName
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="item.prop === 'thirdClass'"
            prop="thirdClassNum"
            label="教学班"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.studentSubjectList.length">{{
                row.studentSubjectList[2].subjectClass
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <template v-if="item.type == 'normal'" slot-scope="{ row }">
            <div v-if="item.prop === 'group'">
              <span
                v-for="(it, ind) in row.studentSubjectList"
                :key="'studentSubjectList' + ind"
              >
                {{ it.subjectName }}
              </span>
            </div>
            <span>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :key="'labelList99'"
          label="操作"
          width="120"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="edit(row)">修改</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="title != '批量搜索'" class="pagination">
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <footer-tab
      v-if="showTab"
      :multiple-selection="multipleSelection"
      :text="'请选择需要删除的学生账号'"
      @delete="deleteStudent"
      @hidden="hidden"
    />
    <el-dialog
      :title="title + '学生'"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div v-if="title === '清除'">
        <div class="filter">
          <div class="row">
            <span>学段：</span>
            <el-select
              v-model="clear.level"
              style="width: 112px"
              placeholder="请选择"
              @change="clearLevelChange"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <span>入学年份：</span>

            <el-select
              v-model="clear.year"
              placeholder="请选择"
              @change="clearYearChange"
            >
              <el-option
                v-for="item in clearGradeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="classList">
          <div class="row">
            <span>班级：</span>
            <el-button type="text" @click="allCheckChange">全选</el-button>
            <el-button type="text" @click="reverseChange">反选</el-button>
            <el-button type="text" @click="cancleAllChange">取消全部</el-button>
          </div>
          <div v-if="classList.length" class="checkbox">
            <el-checkbox-group
              v-model="checkedClass"
              @change="handleCheckedChange"
            >
              <el-checkbox
                v-for="(item, index) in classList"
                :key="index"
                :label="item.classNum"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <no-data v-else></no-data>
        </div>
      </div>
      <div v-if="title === '批量修改'" class="batch">
        <div class="text">温馨提示：</div>
        <div class="text">
          1、批量修改学生将修改除 账号
          以外的所有学生属性信息（包括：姓名、学籍号、年级、班级等）。
        </div>
        <div class="text">
          2、请先使用导出功能导出需要修改的学生，然后在Excel中修改学生信息，最后使用批量修改功能导入Excel修改。
        </div>
        <!-- <div class="template">
          文件上传：
          <el-link
            type="primary"
            href="https://static.wtjy.com/analy/template/%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF(%E6%96%B0%E7%89%88%E6%9C%AC).xls"
            >学生信息模版下载<i class="el-icon-download"></i
          ></el-link>
        </div> -->
      </div>
      <div v-if="title === '批量导入'" class="batch">
        <div class="text">温馨提示：</div>
        <div class="text">
          1、批量导入学生时，请特别注意学生的班级，如“1”和“01”将会被认为是两个不同的班级。
        </div>
        <div class="text">2、导入时避免学生信息重复</div>
        <div class="template">
          文件上传：
          <el-link
            type="primary"
            href="https://static.wtjy.com/cms/template/%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xls"
            >学生信息模版下载<i class="el-icon-download"></i
          ></el-link>
        </div>
      </div>
      <div v-if="title === '批量搜索'" class="batch">
        <div class="template">
          导入模板:
          <el-link
            type="primary"
            href="https://static.wtjy.com/cms/template/%E6%89%B9%E9%87%8F%E6%90%9C%E7%B4%A2%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls"
            >学生信息模版下载<i class="el-icon-download"></i
          ></el-link>
        </div>
      </div>
      <el-upload
        v-if="
          title === '批量修改' || title === '批量导入' || title === '批量搜索'
        "
        ref="upload"
        class="upload-demo"
        name="file"
        drag
        :action="uploadAvatarURL"
        :headers="uploadAvatarHeaders"
        :on-remove="handleRemove"
        :file-list="fileList"
        :on-success="handleOnSuccess"
        :on-error="handleError"
        :auto-upload="false"
        :limit="1"
        :data="title === '批量搜索' ? null : { type: type }"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">请将文件拖放到此处或点击上传</div>
      </el-upload>
      <el-form
        v-if="title === '新增' || title === '编辑'"
        ref="form"
        label-position="right"
        label-width="100px"
        :rules="rules"
        :model="form"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓 名" prop="name">
              <el-input
                v-model="form.name"
                style="width: 260px"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账 号" prop="username">
              <el-input
                v-model="form.username"
                style="width: 260px"
                placeholder="汉字、字母、数字、下划线组合"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="密 码" prop="password">
              <el-input
                v-model="form.password"
                style="width: 260px"
                placeholder="密码只能含有字母、数字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                style="width: 260px"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学段" prop="level">
              <el-select
                v-model="form.level"
                style="width: 260px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入学年份" prop="year">
              <el-select
                v-model="form.year"
                style="width: 260px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in yearOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="行政班" prop="classNum">
              <el-input
                v-model="form.classNum"
                style="width: 260px"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学籍号" prop="studentNo">
              <el-input
                v-model="form.studentNo"
                style="width: 260px"
                placeholder="学籍号只能含有字母、数字"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="考号" prop="studentExamId">
              <el-input
                v-model="form.studentExamId"
                style="width: 260px"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学号" prop="studentHwId">
              <el-input
                v-model="form.studentHwId"
                style="width: 260px"
                placeholder="请输入学号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="选考科目1" prop="firstSubject">
              <el-select
                v-model="form.firstSubject"
                style="width: 122px"
                placeholder="请选择"
                @change="selectChange"
              >
                <el-option
                  v-for="item in electiveCourse"
                  :key="item.value"
                  :label="item.label"
                  :disabled="item.disabled"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="form.firstClassNum"
                style="width: 122px; margin-left: 16px"
                placeholder="请输入教学班"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选考科目2" prop="secondSubject">
              <el-select
                v-model="form.secondSubject"
                style="width: 122px"
                placeholder="请选择"
                @change="selectChange"
              >
                <el-option
                  v-for="item in electiveCourse"
                  :key="item.value"
                  :disabled="item.disabled"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="form.secondClassNum"
                style="width: 122px; margin-left: 16px"
                placeholder="请输入教学班"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="选考科目3" prop="thirdSubject">
              <el-select
                v-model="form.thirdSubject"
                style="width: 122px"
                placeholder="请选择"
                @change="selectChange"
              >
                <el-option
                  v-for="item in electiveCourse"
                  :key="item.value"
                  :label="item.label"
                  :disabled="item.disabled"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="form.thirdClassNum"
                style="width: 122px; margin-left: 16px"
                placeholder="请输入教学班"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选考组合">
              <el-input
                v-model="group"
                placeholder="请输入"
                style="width: 260px"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="学生标记" prop="type">
              <el-input
                v-model="form.type"
                placeholder="请输入"
                maxlength="10"
                style="width: 260px"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="楼栋" prop="building">
              <el-input
                v-model="form.building"
                placeholder="请输入"
                style="width: 260px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼层" prop="floor">
              <el-input
                v-model="form.floor"
                placeholder="请输入"
                style="width: 260px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog
        width="30%"
        title="温馨提示"
        :visible.sync="innerVisible"
        append-to-body
      >
        <div class="clearTip">
          此操作将清除所有选中班级学生，确认清除请输入登录密码，否则点击取消;
        </div>
        <div class="password">
          请输入登录密码：
          <a-input-password
            v-model="password"
            style="width: 150px"
            placeholder="请输入登录密码"
          />
          <!-- <el-input
            v-model="password"
            size="mini"
            show-password
            style="width: 150px"
            placeholder="请输入登录密码"
            type="password"
          ></el-input> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button v-loading="submitLoading" type="danger" @click="clearAll"
            >清除</el-button
          >
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          v-if="title === '清除'"
          type="danger"
          @click="innerVisible = true"
          >清除</el-button
        >
        <el-button
          v-if="title === '批量导入'"
          v-loading="submitLoading"
          type="primary"
          @click="importSubmit"
          >导入</el-button
        >
        <el-button
          v-if="title === '批量修改'"
          v-loading="submitLoading"
          type="primary"
          @click="importSubmit"
          >批量修改</el-button
        >
        <el-button
          v-if="title === '批量搜索'"
          type="primary"
          @click="clearBatchSearch"
          >清除批量搜索</el-button
        >
        <el-button
          v-if="title === '批量搜索'"
          v-loading="submitLoading"
          type="primary"
          @click="importSubmit"
          >导入</el-button
        >
        <el-button
          v-if="title === '新增' || title === '编辑'"
          v-loading="submitLoading"
          type="primary"
          @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import filterComponent from "@/components/filter";
import footerTab from "./component/footerTab.vue";
import { getStore } from "@/core/util/store";
import {
  gradelist,
  getClassNum,
  getStudentNewExam,
  delStudentNewExam,
  addStudent,
  editStudent,
  exportstudent,
  cleanStudent,
  classlistbylevelandyear,
  removeStudent,
  exportAnswerSheet,
} from "@/core/api/newOther";
import { levelOptions } from "@/core/util/constdata";
import { getSubjectList, encryption, getYear } from "@/core/util/util";
import validate from "@/core/util/formValidate";
export default {
  name: "NewOtherStudent",
  components: {
    filterComponent,
    footerTab,
  },
  data() {
    const userMsg = JSON.parse(localStorage.getItem("jzjx-user-message"));
    // const nowYear = new Date().getFullYear();
    // const arr = new Array(nowYear - 2014).fill("");
    // let yearList = arr.map((item, index) => {
    //   return { label: index + 2015, value: index + 2015 };
    // });
    let initLevel = null;
    let levelList = levelOptions();
    if (levelList.length) {
      initLevel = levelList[0].value;
    }
    var phoneReg =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    const validatePhone = (rules, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!phoneReg.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };
    const validUsername = (rule, value, callback) => {
      if (value) {
        if (!/^[\u4e00-\u9fa5a-zA-Z0-9_]+$/.test(value)) {
          callback(new Error("账号格式为汉字、字母、数字、下划线组合"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validHwId = (rule, value, callback) => {
      if (value) {
        if (/^\d{4,12}$/.test(value)) {
          callback();
        } else {
          callback(new Error("请输入4-12位的数字"));
        }
      } else {
        callback();
      }
    };
    return {
      innerVisible: false,
      levelOptions: levelOptions(),
      electiveCourse: [],
      uploadAvatarHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": userMsg.content.schoolId,
      },
      dialogVisible: false,
      fileList: [],
      uploadAvatarURL: "/cms/schoolstudent/import",
      type: "", // 1:批量新增  2:批量修改
      rules: {
        name: validate.normal("请输入学生姓名"),
        level: validate.selectNormal("请选择学段"),
        year: validate.selectNormal("请选择入学年份"),
        classNum: validate.normal("请输入行政班"),
        username: [{ validator: validUsername, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        studentHwId: [{ validator: validHwId, trigger: "blur" }],
        // studentExamId: validate.normal("请输入考号"),
      },
      form: {
        year: "2020",
      },
      showTab: false,
      checkList: [],
      labels: [],
      year: "2020",
      title: "",
      filterOptions: [
        {
          label: "学段",
          type: "select",
          field: "level",
          width: 90,
          placeholder: "全部",
          options: levelOptions(),
          labelKey: "label",
          valueKey: "value",
          handler: () => {
            this.getClassList();
          },
        },
        {
          label: "入学年份",
          field: "year",
          type: "select",
          placeholder: "全部",
          width: 90,
          options: [...getYear()],
          labelKey: "label",
          valueKey: "value",
          handler: () => {
            this.getClassList();
          },
        },
        {
          label: "班级",
          field: "classNum",
          type: "select",
          placeholder: "班级",
          width: 90,
          options: [],
          labelKey: "label",
          valueKey: "value",
        },
        // {
        //   label: "",
        //   type: "input",
        //   field: "studentNo",
        //   placeholder: "请输入学籍号",
        // },
        {
          label: "",
          type: "input",
          width: 100,
          field: "studentName",
          placeholder: "姓名/学号/考号",
        },
      ],
      search: {},
      tableData: [],
      page: {
        current: 1,
        size: 50,
      },
      labelList: [
        {
          label: "学籍号",
          prop: "studentNo",
          width: 200,
          type: "normal",
          sort: 1,
        },
        {
          label: "考号",
          prop: "studentExamId",
          width: 150,
          type: "normal",
          sort: 2,
        },
        {
          label: "学号",
          prop: "studentHwId",
          width: 150,
          type: "normal",
          sort: 3,
        },
        {
          label: "姓名",
          prop: "name",
          width: 120,
          type: "normal",
          sort: 4,
        },
        {
          label: "行政班",
          prop: "classNum",
          width: 100,
          type: "normal",
          sort: 5,
        },
        {
          label: "入学年份",
          prop: "gradeName",
          width: 100,
          type: "normal",
          sort: 6,
        },

        {
          label: "选考科目1",
          prop: "firstClass",
          sort: 7,
        },
        {
          label: "选考科目2",
          prop: "secondClass",
          sort: 8,
        },
        {
          label: "选考科目3",
          prop: "thirdClass",
          sort: 9,
        },
        {
          label: "选考组合",
          prop: "group",
          type: "normal",
          width: 150,
          sort: 10,
        },
        {
          label: "账号",
          prop: "username",
          width: 200,
          type: "normal",
          sort: 11,
        },
        {
          label: "手机号",
          prop: "phone",
          width: 150,
          type: "normal",
          sort: 12,
        },
      ],
      total: 0,
      multipleSelection: [],
      btnLoading: false,
      loading: false,
      userInfo: {},
      submitLoading: false,
      gradeList: [],
      clear: {
        level: initLevel,
        year: new Date().getFullYear(),
      },
      isIndeterminate: false,
      allCheck: false,
      reverse: false,
      cancleAll: false,
      classList: [],
      checkedClass: [],
      older: [],
      chuzhong: [
        { value: 11, label: "道德与法治" },
        {
          value: 4,
          label: "历史",
          index: 8,
        },
        {
          value: 5,
          label: "地理",
          index: 9,
        },
        {
          value: 8,
          label: "物理",
          index: 4,
        },
        {
          value: 9,
          label: "化学",
          index: 5,
        },
        {
          value: 7,
          label: "生物",
          index: 6,
        },
      ],
      gaozhong: [
        {
          value: 6,
          label: "政治",
          index: 7,
        },
        {
          value: 4,
          label: "历史",
          index: 8,
        },
        {
          value: 5,
          label: "地理",
          index: 9,
        },
        {
          value: 8,
          label: "物理",
          index: 4,
        },
        {
          value: 9,
          label: "化学",
          index: 5,
        },
        {
          value: 7,
          label: "生物",
          index: 6,
        },
      ],
      group: "",
      clearGradeList: [...getYear()],
      yearOptions: [...getYear()],
      password: null,
    };
  },
  watch: {
    "form.level": {
      handler() {
        this.filterSubjectName();
        this.selectChange();
      },
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("jzjx-user-message"));
    this.search.schoolId = this.userInfo.content.schoolId;
    this.getGradeList();

    this.getData();
    this.labels = [
      {
        label: "考号",
        prop: "studentExamId",
        width: 150,
        type: "normal",
        sort: 2,
      },
      {
        label: "学号",
        prop: "studentHwId",
        width: 150,
        type: "normal",
        sort: 3,
      },
      {
        label: "姓名",
        prop: "name",
        width: 120,
        type: "normal",
        sort: 4,
      },
      {
        label: "行政班",
        prop: "classNum",
        width: 100,
        type: "normal",
        sort: 5,
      },
      {
        label: "入学年份",
        prop: "gradeName",
        width: 100,
        type: "normal",
        sort: 6,
      },
      {
        label: "选考组合",
        prop: "group",
        type: "normal",
        width: 150,
        sort: 10,
      },
      {
        label: "账号",
        prop: "username",
        width: 200,
        type: "normal",
        sort: 11,
      },
    ];
    this.checkList = [2, 3, 4, 5, 6, 10, 11];
    this.electiveCourse = getSubjectList();
  },
  methods: {
    handleError() {
      this.fileList = [];
      this.submitLoading = false;
      this.$message.error("导入失败");
    },
    clearYearChange() {
      this.getClearClassNum();
    },
    clearLevelChange() {
      this.getClearClassNum();
    },
    selectChange() {
      const arr = [
        this.form.firstSubject,
        this.form.secondSubject,
        this.form.thirdSubject,
      ];
      if (this.electiveCourse) {
        this.electiveCourse.forEach((item) => {
          if (arr.includes(item.value)) {
            this.$set(item, "disabled", true);
          } else {
            this.$set(item, "disabled", false);
          }
        });
        this.getGroup();
      }
    },
    getGroup() {
      let data = [
        this.filterSubjectName(this.form.firstSubject),
        this.filterSubjectName(this.form.secondSubject),
        this.filterSubjectName(this.form.thirdSubject),
      ];
      this.group = data.filter((item) => item).toString();
    },
    filterSubjectName(val) {
      const data = this.electiveCourse.find((item) => item.value == val);
      if (data) {
        return data.label;
      } else {
        return "";
      }
    },
    async getClearClassNum() {
      const res = await classlistbylevelandyear({ ...this.clear });
      this.classList = res.data.data;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.allCheck = checkedCount === this.classList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classList.length;
    },
    allCheckChange() {
      this.checkedClass = this.classList.map((item) => item.classNum);
      this.$forceUpdate();
    },
    reverseChange() {
      const classNum = this.classList.map((item) => item.classNum);
      this.checkedClass = classNum.filter(
        (item) => !this.checkedClass.includes(item)
      );
    },
    cancleAllChange() {
      this.checkedClass = [];
    },
    clearStudent() {
      this.dialogVisible = true;
      this.title = "清除";
      this.getClearClassNum();
    },
    async exportExcel() {
      this.loading = true;
      const res = await exportstudent({ ...this.search });
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: res.data.data.url,
          name: res.data.data.schoolName,
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
      this.loading = false;
    },
    importExcel() {
      this.dialogVisible = true;
      this.uploadAvatarURL = "/cms/schoolstudent/import";
      this.title = "批量导入";
      this.type = 1;
    },
    add() {
      this.title = "新增";
      this.dialogVisible = true;
    },
    batchEdit() {
      this.dialogVisible = true;
      this.title = "批量修改";
      this.uploadAvatarURL = "/cms/schoolstudent/import";
      this.type = 2;
    },
    batchSearch() {
      this.title = "批量搜索";
      this.dialogVisible = true;
      this.uploadAvatarURL = "/cms/schoolstudent/importsearch";
    },
    exportStuSheet() {
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择需要导出答题卡的学生!",
          type: "warning",
        });
        return;
      }
      let ids = this.multipleSelection.map((item) => item.id);

      let data = {
        studentIds: ids.join(","),
      };
      this.btnLoading = true;
      exportAnswerSheet(data)
        .then((res) => {
          let { name, url } = res.data;
          // 跳转下载页面
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: url,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    edit(row) {
      this.dialogVisible = true;
      this.title = "编辑";
      const [a, b, c] = row.studentSubjectList;
      this.form = { ...row, level: row.level ? row.level.toString() : null };
      this.form.year = row.year.toString();
      this.group = row.choiceExam;
      this.form.firstSubject = a?.subjectId ? a.subjectId : "";
      this.form.secondSubject = b?.subjectId ? b.subjectId : "";
      this.form.thirdSubject = c?.subjectId ? c.subjectId : "";
      this.form.firstClassNum = a?.subjectClass ? a.subjectClass : "";
      this.form.secondClassNum = b?.subjectClass ? b.subjectClass : "";
      this.form.thirdClassNum = c?.subjectClass ? c.subjectClass : "";
    },
    handleRemove() {},
    handleOnSuccess(response) {
      if (response.code == 1) {
        this.submitLoading = false;

        if (response.msg.indexOf("http") != -1) {
          let reg = /[\u4e00-\u9fa5]/g;
          let names = response.msg.match(reg);
          let name = names.join("");
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: response.msg,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.submitLoading = false;
        } else {
          this.$message.error(response.msg);
          this.submitLoading = false;
        }
        this.getData();
        this.submitLoading = false;
      } else {
        if (this.title === "批量搜索") {
          this.tableData = response.data;
          this.$message({
            type: "success",
            showClose: true,
            message: "导入成功!",
          });
          this.fileList = [];
          this.submitLoading = false;
        } else {
          this.getData();
          this.$message({
            type: "success",
            showClose: true,
            message: "导入成功!",
          });
          this.fileList = [];
          this.submitLoading = false;
        }
      }
      this.dialogVisible = false;
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.submitLoading = false;
      this.fileList = [];
    },
    importSubmit() {
      if (this.$refs.upload.uploadFiles.length) {
        this.submitLoading = true;
        this.$refs.upload.submit();
      } else {
        this.$message({
          showClose: true,
          message: "请选择上传文件",
          type: "warning",
        });
      }
    },
    clearBatchSearch() {
      this.title = "";
      this.fileList = [];
      this.dialogVisible = false;
      this.getData();
    },

    deleteStudent() {
      this.loading = true;
      removeStudent({
        ids: this.multipleSelection.map((item) => item.id).toString(),
      })
        .then(() => {
          this.getData();
          this.loading = false;
          this.$message({
            type: "success",
            showClose: true,
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.loading = false;
        });
      this.showTab = false;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.title === "新增") {
            try {
              this.submitLoading = true;
              await addStudent({ ...this.form });
              this.dialogVisible = false;
              this.$message.success("提交成功");
              this.submitLoading = false;
              if (this.$refs.form) {
                this.$refs.form.resetFields();
              }
              this.form = {};
              this.getData();
              this.group = "";
            } catch {
              this.submitLoading = false;
            }
          } else if (this.title === "编辑") {
            try {
              this.submitLoading = true;
              this.$delete(this.form, "gradeId");
              this.$delete(this.form, "gradeName");
              await editStudent({ ...this.form });
              this.dialogVisible = false;
              if (this.$refs.form) {
                this.$refs.form.resetFields();
              }
              this.$message({
                type: "success",
                showClose: true,
                message: "提交成功!",
              });
              this.submitLoading = false;
              this.form = {};
              this.getData();
              this.group = "";
            } catch {
              this.submitLoading = false;
            }
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {};
      this.group = "";
      this.fileList = [];
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
    },
    checkListChange() {
      const data = this.labelList.filter((item) =>
        this.checkList.includes(item.sort)
      );
      this.labels = data.sort((a, b) => a.sort - b.sort);
    },
    del(row) {
      this.loading = true;
      this.$confirm("此操作将永久删除该学生信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await delStudentNewExam({ id: row.id });
            this.$message({
              type: "success",
              showClose: true,
              message: "删除成功!",
            });
            this.loading = false;
            this.getData();
          } catch {
            this.$message({
              type: "info",
              message: "删除失败",
              showClose: true,
            });
            this.getData();
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            type: "info",
            message: "已取消删除",
            showClose: true,
          });
        });
    },
    hidden() {
      this.showTab = false;
      this.multipleSelection = [];
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
    async getGradeList() {
      const res = await gradelist({ level: this.search.level });
      this.gradeList = res.data.data;
    },

    async getClassList() {
      if (this.search.year && this.search.level) {
        const res = await getClassNum({
          year: this.search.year,
          level: this.search.level,
        });
        this.filterOptions[2].options = res.data.data;
      } else {
        this.filterOptions[2].options = [];
      }
    },
    getFilterValue(val) {
      this.search = { ...val };
      this.page.current = 1;
      this.getData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.showTab = true;
      }
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getStudentNewExam({ ...this.search, ...this.page });
        this.loading = false;
        this.total = res.data.data.total;
        this.tableData = res.data.data.records;
      } catch {
        this.loading = false;
      }
    },
    clearAll() {
      const password = this.password;
      let randomStr = "blockPuzzle";
      let code = "xxx";
      const user = encryption({
        data: { password, randomStr, code },
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      this.submitLoading = true;
      let params = {
        classNum: this.checkedClass.toString(),
        gradeId: this.classList.length && this.classList[0].gradeId,
        ...this.clear,
        passwordTemp: user.password,
      };
      cleanStudent(params)
        .then(() => {
          this.getData();
          this.$message({
            showClose: false,
            message: "删除成功",
            type: "success",
          });
          this.submitLoading = this.dialogVisible = this.innerVisible = false;
          this.checkedClass = [];
          this.password = "";
        })
        .catch(() => {
          this.submitLoading = this.dialogVisible = this.innerVisible = false;
          this.checkedClass = [];
          this.password = "";
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.student {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    flex-wrap: wrap;
    .operate-btn {
      @media screen and (max-width: 1686px) {
        margin-top: 24px;
      }
    }
  }
  .tableList {
    margin-bottom: 24px;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}
.el-table {
  &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      height: 10px;
    }
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 8px;
      height: 8px;
      background: #a1a1a1; //滚动条颜色
    }
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
      background: #ececec; //滚动条背景色
      height: 8px;
    }
  }
}
::v-deep .el-table__fixed {
  height: calc(100% - 10px) !important;
}
.batch {
  line-height: 24px;
  font-size: 14px;
  color: #101011;
  .template {
    margin-top: 24px;
    margin-bottom: 30px;
  }
}
::v-deep .el-upload-dragger {
  width: 100% !important;
}
::v-deep .el-upload {
  width: 100% !important;
}
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  .row {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
}
.checkbox {
  margin-left: 45px;
}
.classList {
  ::v-deep .el-checkbox {
    width: 85px;
    margin: 10px 0;
  }
}
.clearTip {
  margin-bottom: 10px;
}
</style>
